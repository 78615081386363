import { Component, OnInit, HostListener } from '@angular/core';
import {Image} from '../Image';
import * as firebase from 'firebase/app';
declare function require(name: string);

@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.css'],
  host: {
    '(document:keypress)': 'handleKeyboardEvent($event)'
  }
})
export class PhotosComponent implements OnInit {
  images: Image[][] = new Array();
  imageDiag: string;
  numYears: number;

  @HostListener('document:keydown', ['$event']) handleKeyboardEvent(event: KeyboardEvent) {
    console.log('jere')
    if (event.keyCode === 39) {
      this.goToNext(this.imageDiag);
    }

    else if (event.keyCode === 37) {
      this.goToPrev(this.imageDiag);
    }
  }
  constructor() {
    this.imageDiag = '';
  }

  ngOnInit() {

    this.readImageMeta();
  }

  readImageMeta(){
    const db = firebase.firestore();

    db.collection('photos').orderBy('year', 'desc').onSnapshot((querySnapshot) => {
      this.numYears = querySnapshot.size
      querySnapshot.forEach((doc) => {
        const imageYear: Image[] = new Array();
        this.putImage(doc.data().year, doc.data().numPics, imageYear);
      });
    });
  }

  putImage(year: number, numPics: number, array: any) {
    // const p1 = new Image('./assets/images/gallary/1.jpg', 'Canada Day 2017');
    // const p2 = new Image('./assets/images/gallary/2.jpg', 'Canada Day 2017');
    // const p3 = new Image('./assets/images/gallary/3.jpg', 'Canada Day 2017');
    // const p4 = new Image('./assets/images/gallary/4.jpg', 'Canada Day 2017');
    // const p5 = new Image('./assets/images/gallary/5.jpg', 'Canada Day 2017');
    // const p6 = new Image('./assets/images/gallary/6.jpg', 'Canada Day 2017');
    // const p7 = new Image('./assets/images/gallary/7.jpg', 'Canada Day 2017');
    // const p8 = new Image('./assets/images/gallary/8.jpg', 'Canada Day 2017');
    // const p9 = new Image('./assets/images/gallary/9.jpg', 'Canada Day 2017');
    // const p10 = new Image('./assets/images/gallary/10.jpg', 'Canada Day 2017');
    // const p11 = new Image('./assets/images/gallary/11.jpg', 'Canada Day 2017');
    // const p12 = new Image('./assets/images/gallary/12.jpg', 'Canada Day 2017');
    // const p13 = new Image('./assets/images/gallary/13.jpg', 'Canada Day 2017');
    // const p14 = new Image('./assets/images/gallary/14.jpg', 'Canada Day 2017');
    // const p15 = new Image('./assets/images/gallary/15.jpg', 'Canada Day 2017');
    // const p16 = new Image('./assets/images/gallary/16.jpg', 'Canada Day 2017');
    // const p17 = new Image('./assets/images/gallary/17.jpg', 'Canada Day 2017');
    // this.images.push(p1);
    // this.images.push(p2);
    // this.images.push(p3);
    // this.images.push(p4);
    // this.images.push(p5);
    // this.images.push(p6);
    // this.images.push(p7);
    // this.images.push(p8);
    // this.images.push(p9);
    // this.images.push(p10);
    // this.images.push(p11);
    // this.images.push(p12);
    // this.images.push(p13);
    // this.images.push(p14);
    // this.images.push(p15);
    // this.images.push(p16);
    // this.images.push(p17);


    const storage = firebase.storage();
    const storageRef = storage.ref();



    for (let i = 0; i < numPics; i++) {
      const ref = storageRef.child('photos/' + year.toString() + '/' + i.toString() + '.jpg');
      ref.getDownloadURL().then((url) => {
        const p1 = new Image(url, year.toString());
        array.push(p1);
        console.log(url);
      });
    }
    this.images.push(array);


  }

  diagOpen(source: string) {
    this.imageDiag = source;

    const mdcDialog = require('@material/dialog');
    const MDCDialog = mdcDialog.MDCDialog;
    const MDCDialogFoundation = mdcDialog.MDCDialogFoundation;
    const util = mdcDialog.util;


    const dialog = new MDCDialog(document.querySelector('#viewPic'));

    dialog.show();
  }
  goToNext(source: string) {

    try{
      for (let i = 0; i < this.images.length; i++) {
        for (let j = 0; j < this.images[i].length; j++) {
          if (this.images[i][j].getImageSource() === source) {
            this.imageDiag = this.images[i][j + 1].getImageSource();
            break;
          }
        }
      }
    } catch (e) {
      this.imageDiag = this.images[1][0].getImageSource();
    }

  }
  goToPrev(source: string) {
    try {
      for (var i = 0; i < this.images.length; i++) {
        for (let j = 0; j < this.images[j].length; j++){
          if (this.images[i][j].getImageSource() === source) {
            this.imageDiag = this.images[i][j - 1].getImageSource();
            break;
          }
        }
      }
    } catch (e) {
      this.imageDiag = this.images[this.images.length - 1][0].getImageSource();
    }
  }

}
