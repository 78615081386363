export class Sponsor {
  private sponsorName: string;
  private sponsorCat: string;
  private imageLink: string;
  private webLink: string;

  constructor(spName: string, spCat: string, imgLink: string, wLink: string) {
    this.sponsorName = spName;
    this.sponsorCat = spCat;
    this.imageLink = imgLink;
    this.webLink = wLink;
  }
  getSponsorName() {
    return this.sponsorName;
  }
  getSponsorCat() {
    return this.sponsorCat;
  }
  getImageLink() {
    return this.imageLink;
  }

  getWebLink(){
    return this.webLink
  }
}
