// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import * as firebase from 'firebase';
export const environment = {
  production: true,
  config : {
    apiKey: "AIzaSyBtg3ktdcc1XGk4tH527gpZ8z150_rcr9k",
    authDomain: "canadadayincumberland.firebaseapp.com",
    databaseURL: "https://canadadayincumberland.firebaseio.com",
    projectId: "canadadayincumberland",
    storageBucket: "canadadayincumberland.appspot.com",
    messagingSenderId: "1072308656140"
  }
};

firebase.initializeApp(environment.config);


/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
