export class Image {
  private imagesrc: string;
  private imagetext: string;
  constructor(source: string, imagetext: string) {
    this.imagesrc = source;
    this.imagetext = imagetext;
  }
  getImageSource() {
    return this.imagesrc;
  }
  getImageText() {
    return this.imagetext;
  }
}
