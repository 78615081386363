import {Component, EventEmitter, OnInit} from '@angular/core';
import { MatGridList, MatGridTile, MatCard, MatCardContent, MatTabGroup, MatAccordion, MatTabHeader} from '@angular/material';
import {RouterModule} from '@angular/router';
import {FooterItem} from './FooterItem';
declare function require(name: string);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Canada Day In Cumberland';
  footerItems: FooterItem[];
  constructor() {
  }

  ngOnInit() {
    this.footerItems = new Array();
    const objex = new FooterItem('Objex, Inc.', 'https://pbs.twimg.com/profile_images/958426205543333888/1IfMW2wk_400x400.jpg', 'http://www.objex.ca');
    const walbake = new FooterItem('The Black Walnut Bakery', 'http://www.blackwalnutbakery.ca/media/1237/menu_square.jpg', 'http://www.blackwalnutbakery.ca/');
    const walbake2 = new FooterItem('Black Walnut Bakery', 'http://www.blackwalnutbakery.ca/media/1237/menu_square.jpg', 'http://www.blackwalnutbakery.ca/');
    this.footerItems.push(objex);
    this.footerItems.push(walbake);
    this.footerItems.push(walbake2);
    this.footerItems.push(objex);
  }

  goUrl(weblink: string) {
  }

}
