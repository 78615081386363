import {Sponsor} from './Sponsor';

export class Section {
  name: string;
  sp: Sponsor[];
  constructor(nm: string, sponsors: Sponsor[]){
    this.name = nm;
    this.sp = sponsors;
  }
}
