export class FooterItem {
  name: string;
  imageLink: string;
  webLink: string;
  icon: string;

  constructor(itemName: string, itemImage: string, weblink1: string) {
    this.name = itemName;
    this.imageLink = itemImage;
    this.webLink = weblink1;
  }
}
