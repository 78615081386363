import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import {
  MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule,
  MatGridList, MatGridListModule, MatMenuModule, MatCardModule, MatTabGroup, MatTabsModule, MatFormFieldModule,
  MatOptionModule, MatTableModule, MatSelectModule, MatInputModule, MatPaginatorModule, MatSortModule, MatButtonToggle,
  MatButtonToggleModule, MatDatepickerModule, MatNativeDateModule, MatAccordion, MatAccordionDisplayMode, MatIconRegistry
} from '@angular/material';
import {RouterModule, Routes} from '@angular/router';
import {AgmCoreModule} from '@agm/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatStepperModule} from '@angular/material/stepper';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {HttpClientModule} from '@angular/common/http';
import { HomeComponent } from './home/home.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { PhotosComponent } from './photos/photos.component';
import { SponsorsComponent } from './sponsors/sponsors.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import { TabsComponent } from './tabs/tabs.component';
import { MobilenavComponent } from './mobilenav/mobilenav.component';
import { MainComponent } from './main/main.component';
import { SponsorsfilterPipe } from './pipes/sponsorsfilter.pipe';


const routes: Routes = [
  {path: '', component: MainComponent },
  {path: 'home', component: HomeComponent},
  {path: 'schedule', component: ScheduleComponent},
  {path: 'photos', component: PhotosComponent},
  {path: 'sponsors', component: SponsorsComponent}
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ScheduleComponent,
    PhotosComponent,
    SponsorsComponent,
    TabsComponent,
    MobilenavComponent,
    MainComponent,
    SponsorsfilterPipe,
  ],
  imports: [
    RouterModule.forRoot(
      routes,
      { enableTracing: true } // <-- debugging purposes only
    ),
    BrowserModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatTabsModule,
    MatFormFieldModule,
    MatOptionModule,
    MatTableModule,
    MatSelectModule,
    MatInputModule,
    MatPaginatorModule,
    MatSortModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBxsqW4qYptx5ZxuySBPw1pdAeFU1h5Uuc'
    }),
    ReactiveFormsModule,
    MatStepperModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatIconModule,
    HttpClientModule,
    FlexLayoutModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer){
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg'));
  }
}
