import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sponsorsfilter'
})
export class SponsorsfilterPipe implements PipeTransform {

  transform(sponsors: any, args?: any): any {
    if (args === undefined) return sponsors;
    return sponsors.filter(function (sponsors) {
      return sponsors.getSponsorCat().toLowerCase().includes(args.toLowerCase());
    });
  }

}
